// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  patientregistration: getIcon('ic_patientregister'),
  insurance: getIcon('ic_insurance'),
  patients: getIcon('ic_patient'),
  patientmonitoring: getIcon('ic_patientmonitor'),
  pendingforms: getIcon('ic_pendingforms'),
  allpatientvitals: getIcon('ic_patientvitals'),
  appointments: getIcon('ic_appointments'),
  careplan: getIcon('ic_careplan'),
  departments: getIcon('ic_departments'),
  doctors: getIcon('ic_doctor'),
  user: getIcon('ic_user'),
  forms: getIcon('ic_forms'),
  email: getIcon('ic_email'),
  setting: getIcon('ic_setting'),
  webcms: getIcon('ic_webcms'),
  blog: getIcon('ic_blog'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Dashboard', path: '/dashboard/maindashboard', icon: ICONS.dashboard },
      { title: 'Patient Registration', path: '/dashboard/patientregistration', icon: ICONS.patientregistration },
      { title: 'Insurance', path: '/dashboard/insurance', icon: ICONS.insurance },
      { title: 'Patients', path: '/dashboard/patients', icon: ICONS.patients },
      {
        title: 'Patient Monitoring',
        path: '/dashboard/patientmonitoring',
        icon: ICONS.patientmonitoring,
        children: [
          { title: 'Questionnnaires Assignment', path: '/dashboard/patientmonitoring/questionnairesassignment' },
          { title: 'TMP Graph', path: '/dashboard/patientmonitoring/tmpgraph' },
          { title: 'TMP History', path: '/dashboard/patientmonitoring/tmphistory' },
          { title: 'TMP Questionnnaires', path: '/dashboard/patientmonitoring/tmpquestionnaires' },
        ],
      },
      { title: 'Pending Forms', path: '/dashboard/pendingforms', icon: ICONS.pendingforms },
      { title: 'All Patient vitals', path: '/dashboard/allpatientvitals', icon: ICONS.allpatientvitals },
      { title: 'Appointments', path: '/dashboard/appointments', icon: ICONS.appointments },
      { title: 'CarePlan', path: '/dashboard/careplan', icon: ICONS.careplan },
      { title: 'Departments', path: '/dashboard/departments', icon: ICONS.departments },
      { title: 'Doctors', path: '/dashboard/doctors', icon: ICONS.doctors },
      {
        title: 'User',
        path: '/dashboard/user',
        icon: ICONS.user,
        children: [
          { title: 'Users', path: '/dashboard/user/users' },
          { title: 'List Of Passwords', path: '/dashboard/user/listofpassword' },
          { title: 'User Role', path: '/dashboard/user/userrole' }  

        ],
      },
      {
        title: 'Forms',
        path: '/dashboard/forms',
        icon: ICONS.forms,
        children: [
          { title: 'Forms List', path: '/dashboard/forms/formslist' },
          { title: 'All Forms', path: '/dashboard/forms/allforms' },
          { title: 'Assign Forms', path: '/dashboard/forms/assignforms' },
        ],
      },
      {
        title: 'Email',
        path: '/dashboard/email',
        icon: ICONS.email,
        children: [
          { title: 'Send Email', path: '/dashboard/email/sendemail' },
          { title: 'Send Bulk Email', path: '/dashboard/email/sendbulkemail' },
          { title: 'Email Log', path: '/dashboard/email/emaillog' },
          { title: 'Email Setting', path: '/dashboard/email/emailsettings' },
          { title: 'Email Template', path: '/dashboard/email/emailtemplate' },
        ],
      },
      /* 
      {
        title: 'Setting',
        path: '/dashboard/setting',
        icon: ICONS.setting,
        children: [
          { title: 'System Info', path: '/dashboard/setting/systeminfo' },
          { title: 'Taxes', path: '/dashboard/setting/taxes' },
          { title: 'Payment Methods', path: '/dashboard/setting/paymentmethods' },
          { title: 'Payment Gateway', path: '/dashboard/setting/paymentgateway' },
          { title: 'Clinical Notes', path: '/dashboard/setting/clinicalnotes' },
          { title: 'Invoice Items', path: '/dashboard/setting/invoiceitems' },
          { title: 'Expanse Type', path: '/dashboard/setting/expansetype' },
          { title: 'Suppliers', path: '/dashboard/setting/suppliers' },
        ],
      }, */
      {
        title: 'Blog',
        path: '/dashboard/blog',
        icon: ICONS.blog,
        children: [
          { title: 'Blogs', path: '/dashboard/blog/blog' },
          { title: 'Category', path: '/dashboard/blog/category' },
          { title: 'Comments', path: '/dashboard/blog/comments' },
        ],
      },
    ],
  },

  
];

export default sidebarConfig;
