import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// components
import LoadingScreen from '../components/LoadingScreen';
import { Login } from '@mui/icons-material';
import FormsList from 'src/pages/Forms/FormsList';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
   
    {
      path: '/login',
      element: <Login />,
    },

    {
      path: '/',
      element: <Navigate to="/dashboard/maindashboard" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/maindashboard" replace />, index: true },
        { path: '/dashboard/maindashboard', element: <PageOne /> },
        
        
        // Patient registration
        { path: '/dashboard/patientregistration', element: <PatientRegistration /> },
        { path: '/dashboard/patientregistration/addpatient', element: <AddPatient /> },
        { path: '/dashboard/patientregistration/assigndoctor', element: <AssignDoctor /> },
        { path: '/dashboard/patientregistration/insuranceinformation', element: <InsuranceInformation /> },

        // Insurance 
        { path: '/dashboard/insurance', element: <Insurance /> },
        { path: '/dashboard/insurance/insuranceinformation1', element: <InsuranceInformation1 /> },
        { path: '/dashboard/insurance/personalinfo', element: <PersonalInfo /> },
      
        // Patients 
        { path: '/dashboard/patients', element: <Patients /> },
        { path: '/dashboard/patients/addpatient1', element: <AddPatient1 /> },
        { path: '/dashboard/patients/patientinsuranceinfo', element: <PatientInsuranceInfo /> },
        { path: '/dashboard/patients/agreement', element: <Agreement /> },
        { path: '/dashboard/patients/consent', element: <Consent /> },
        { path: '/dashboard/patients/emergency', element: <Emergency /> },
        { path: '/dashboard/patients/financial', element: <Financial /> },
        { path: '/dashboard/patients/healthprovider', element: <HealthProvider /> },
        { path: '/dashboard/patients/hipaa', element: <Hipaa /> },
        { path: '/dashboard/patients/insinfo', element: <InsInfo /> },
        { path: '/dashboard/patients/medications', element: <Medications /> },
        { path: '/dashboard/patients/nonprescriptions', element: <NonPrescription /> },
        { path: '/dashboard/patients/prescriptions', element: <Prescription /> },
        

        // Patient Monitoring 
        {
          path: 'patientmonitoring',
          children: [
            { element: <Navigate to="/dashboard/patientmonitoring/questionnairesassignment" replace 
             />, index: true },
            { path: '/dashboard/patientmonitoring/questionnairesassignment', element: 
              <QuestionnairesAssignment /> },
            { path: '/dashboard/patientmonitoring/tmphistory', element: <TmpHistory /> },
            { path: '/dashboard/patientmonitoring/tmpgraph', element: <TmpGraph /> },
            { path: '/dashboard/patientmonitoring/tmpquestionnaires', element: <TmpQuestionnaires /> },
            { path: '/dashboard/patientmonitoring/tmpquestionnaires/bdi', element: <Bdi /> },
            { path: '/dashboard/patientmonitoring/tmpquestionnaires/ciss', element: <Ciss /> }, 
            { path: '/dashboard/patientmonitoring/tmpquestionnaires/ess', element: <Ess /> },
            { path: '/dashboard/patientmonitoring/tmpquestionnaires/hit', element: <Hit /> },
            { path: '/dashboard/patientmonitoring/tmpquestionnaires/lawton', element: <Lawton /> }, 
            { path: '/dashboard/patientmonitoring/tmpquestionnaires/mb', element: <Mb /> },
            { path: '/dashboard/patientmonitoring/tmpquestionnaires/medical', element: <Medical /> },
            { path: '/dashboard/patientmonitoring/tmpquestionnaires/mna', element: <Mna /> },
            { path: '/dashboard/patientmonitoring/tmpquestionnaires/nphq', element: <Nphq /> },
            { path: '/dashboard/patientmonitoring/tmpquestionnaires/npi', element: <Npi /> },
            { path: '/dashboard/patientmonitoring/tmpquestionnaires/phq', element: <Phq /> },
            { path: '/dashboard/patientmonitoring/tmpquestionnaires/rpcs', element: <Rpcs /> },
          ],
        },

        // Pending Forms
        { path: '/dashboard/pendingforms', element: <PendingForms /> },

        // All Patient Vitals
        { path: '/dashboard/allpatientvitals', element: <PatientVitals /> },

        // Appointments 
        { path: '/dashboard/appointments', element: <Appointments /> },
        { path: '/dashboard/appointments/newappointment', element: <NewAppointment /> },

        // Care Plan
        { path: '/dashboard/careplan', element: <CarePlan /> },
        { path: '/dashboard/careplan/newcareplan', element: <NewCarePlan /> },

        // Department
        { path: '/dashboard/departments', element: <Departments /> },
        { path: '/dashboard/departments/newdepartments', element: <NewDepartments /> },

        // Doctors 
        { path: '/dashboard/doctors', element: <Doctors /> },
        { path: '/dashboard/doctors/adddoctor', element: <AddDoctor /> },
        { path: '/dashboard/doctors/docaddress', element: <DocAddress /> },
        { path: '/dashboard/doctors/docappointmentinfo', element: <DocAppointmentinfo /> },
        { path: '/dashboard/doctors/docbasicinfo', element: <DocBasicinfo /> },
        { path: '/dashboard/doctors/docholidays', element: <DocHolidays /> },
        { path: '/dashboard/doctors/docwebdata', element: <DocWebdata /> },

        // User 
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/users" replace 
             />, index: true },
        { path: '/dashboard/user/users', element: <Users /> },
        { path: '/dashboard/user/addaddress', element: <AddAddress /> },
        { path: '/dashboard/user/addbasicinfo', element: <AddBasicinfo /> },
        { path: '/dashboard/user/adduser', element: <AddUser /> },
        { path: '/dashboard/user/listofpassword', element: <ListOfPassword /> },
        { path: '/dashboard/user/newuserrole', element: <NewUserRole /> },
        { path: '/dashboard/user/userrole', element: <UserRole /> },
        ]
      },

        // Forms 
        {
          path: 'forms',
          children: [
            { element: <Navigate to="/dashboard/forms/allforms" replace 
             />, index: true },
             { path: '/dashboard/forms/allforms', element: <Forms /> },
             { path: '/dashboard/forms/activitiesform', element: <ActivitiesForm /> },
        { path: '/dashboard/forms/adhd', element: <Adhd /> },
        { path: '/dashboard/forms/assignforms', element: <AssignForms /> },
        { path: '/dashboard/forms/formslist', element: <FormsList /> },
        { path: '/dashboard/forms/caic', element: <Caic /> },
        { path: '/dashboard/forms/cpcr', element: <Cpcr /> },
        { path: '/dashboard/forms/facialpain', element: <FacialPain /> },
        { path: '/dashboard/forms/gad', element: <Gad /> },
        { path: '/dashboard/forms/stigmaform', element: <StigmaForm /> },
        { path: '/dashboard/forms/sunshineact', element: <SunshineAct /> },
        { path: '/dashboard/forms/neuroconsent', element: <NeuroConsent /> },
        { path: '/dashboard/forms/rpcs', element: <Rpcs /> },
        { path: '/dashboard/forms/hipaa2', element: <Hipaa2 /> },
        { path: '/dashboard/forms/newform', element: <NewForm /> },
          ]
        },

        // Email 
        {
          path: 'email',
          children: [
            { element: <Navigate to="/dashboard/email/sendemail" replace 
             />, index: true },
        { path: '/dashboard/email/caregiver', element: <CareGiver /> },
        { path: '/dashboard/email/emaillog', element: <EmailLog /> },
        { path: '/dashboard/email/emailsettings', element: <EmailSettings /> },
        { path: '/dashboard/email/emailtemplate', element: <EmailTemplate /> },
        { path: '/dashboard/email/forgotpassword', element: <ForgotPassword /> },
        { path: '/dashboard/email/newadmin', element: <NewAdmin /> },
        { path: '/dashboard/email/newappointment1', element: <NewAppointment1 /> },
        { path: '/dashboard/email/newinvoice', element: <NewInvoice /> },
        { path: '/dashboard/email/newpatient', element: <NewPatient /> },
        { path: '/dashboard/email/newrequest', element: <NewRequest /> },
        { path: '/dashboard/email/newwebsite', element: <NewWebsite /> },
        { path: '/dashboard/email/paymentconfirmation', element: <PaymentConfirmation /> },
        { path: '/dashboard/email/resetpassword', element: <ResetPassword /> },
        { path: '/dashboard/email/sendbulkemail', element: <SendBulkEmail /> },
        { path: '/dashboard/email/sendemail', element: <SendEmail /> },
          ]
        }, 

        // Setting 
        {
          path: 'setting',
          children: [
            { element: <Navigate to="/dashboard/setting/systeminfo" replace 
             />, index: true },
             { path: '/dashboard/setting/systeminfo', element: <SystemInfo /> },
             { path: '/dashboard/setting/address', element: <Address /> },
             { path: '/dashboard/setting/basicinfo', element: <BasicInfo /> },
             { path: '/dashboard/setting/clinicalnotes', element: <ClinicalNotes /> },
             { path: '/dashboard/setting/expensetype', element: <ExpenseType /> },
             { path: '/dashboard/setting/invoiceitems', element: <InvoiceItems /> },
             { path: '/dashboard/setting/newexpense', element: <NewExpense /> },
             { path: '/dashboard/setting/newitem', element: <NewItem /> },
             { path: '/dashboard/setting/newnote', element: <NewNote /> },
             { path: '/dashboard/setting/newpayment', element: <NewPayment /> },
             { path: '/dashboard/setting/newsupplier', element: <NewSupplier /> },
             { path: '/dashboard/setting/newtax', element: <NewTax /> },
             { path: '/dashboard/setting/paymentgateway', element: <PaymentGateway /> },
             { path: '/dashboard/setting/paymentmethod', element: <PaymentMethod /> },
             { path: '/dashboard/setting/sociallink', element: <SocialLink /> },
             { path: '/dashboard/setting/suppliers', element: <Suppliers /> },
             { path: '/dashboard/setting/taxrate', element: <TaxRate /> },
         ]
        }, 

        // Blog
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/blogs" replace 
             />, index: true },
             { path: '/dashboard/blog/blog', element: <Blog /> },
             { path: '/dashboard/blog/category', element: <Category /> },
             { path: '/dashboard/blog/comment', element: <Comment /> },
             { path: '/dashboard/blog/newpost', element: <NewPost /> },
          ]
        },
      ],
    },
    
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}


// Dashboard
const PageOne = Loadable(lazy(() => import('../pages/PageOne')));

// Patient Registration
const PatientRegistration = Loadable(lazy(() => import('../pages/PatientRegistration/PatientRegistration')));
const AddPatient = Loadable(lazy(() => import('../pages/PatientRegistration/Addpatient')));
const AssignDoctor = Loadable(lazy(() => import('../pages/PatientRegistration/AssignDoctor')));
const InsuranceInformation = Loadable(lazy(() => import('../pages/PatientRegistration/InsuranceInformation')));

// Insurance 
const Insurance = Loadable(lazy(() => import('../pages/Insurance/Insurance')));
const InsuranceInformation1 = Loadable(lazy(() => import('../pages/Insurance/InsuranceInformation1')));
const PersonalInfo = Loadable(lazy(() => import('../pages/Insurance/PersonalInfo')));

// Patients 
const AddPatient1 = Loadable(lazy(() => import('../pages/Patient/Addpatient1')));
const PatientInsuranceInfo = Loadable(lazy(() => import('../pages/Patient/PatientInsuranceInfo')));
const Agreement = Loadable(lazy(() => import('../pages/Patient/Agreement')));
const Consent = Loadable(lazy(() => import('../pages/Patient/Consent')));
const Emergency = Loadable(lazy(() => import('../pages/Patient/Emergency')));
const Financial = Loadable(lazy(() => import('../pages/Patient/Financial')));
const HealthProvider = Loadable(lazy(() => import('../pages/Patient/HealthProvider')));
const Hipaa = Loadable(lazy(() => import('../pages/Patient/Hipaa')));
const InsInfo = Loadable(lazy(() => import('../pages/Patient/InsInfo')));
const Medications = Loadable(lazy(() => import('../pages/Patient/Medications')));
const NonPrescription = Loadable(lazy(() => import('../pages/Patient/NonPrescription')));
const Patients = Loadable(lazy(() => import('../pages/Patient/Patients')));
const Prescription = Loadable(lazy(() => import('../pages/Patient/Prescription')));

// Patient Monitoring 
const QuestionnairesAssignment = Loadable(lazy(() => import('../pages/PatientMonitoring/QuestionnairesAssignment')));
const TmpHistory = Loadable(lazy(() => import('../pages/PatientMonitoring/TmpHistory')));
const TmpGraph = Loadable(lazy(() => import('../pages/PatientMonitoring/TmpGraph')));
const TmpQuestionnaires = Loadable(lazy(() => import('../pages/PatientMonitoring/TmpQuestionnaires')));
const Bdi = Loadable(lazy(() => import('../pages/PatientMonitoring/Bdi')));
const Ciss = Loadable(lazy(() => import('../pages/PatientMonitoring/Ciss')));
const Ess = Loadable(lazy(() => import('../pages/PatientMonitoring/Ess')));
const Hit = Loadable(lazy(() => import('../pages/PatientMonitoring/Hit')));
const Lawton = Loadable(lazy(() => import('../pages/PatientMonitoring/Lawton')));
const Mb = Loadable(lazy(() => import('../pages/PatientMonitoring/Mb')));
const Medical = Loadable(lazy(() => import('../pages/PatientMonitoring/Medical')));
const Mna = Loadable(lazy(() => import('../pages/PatientMonitoring/Mna')));
const Nphq = Loadable(lazy(() => import('../pages/PatientMonitoring/Nphq')));
const Npi = Loadable(lazy(() => import('../pages/PatientMonitoring/Npi')));
const Phq = Loadable(lazy(() => import('../pages/PatientMonitoring/Phq')));
const Rpcs = Loadable(lazy(() => import('../pages/PatientMonitoring/Rpcs')));

// Pending Forms
const PendingForms = Loadable(lazy(() => import('../pages/PendingForms/PendingForms')));

// All Patient Vitals 
const PatientVitals = Loadable(lazy(() => import('../pages/PatientVitals/PatientVitals')));

// Appointments
const Appointments = Loadable(lazy(() => import('../pages/Appointments/Appointments')));
const NewAppointment = Loadable(lazy(() => import('../pages/Appointments/NewAppointment')));

// Care Plan 
const CarePlan = Loadable(lazy(() => import('../pages/CarePlan/CarePlan')));
const NewCarePlan = Loadable(lazy(() => import('../pages/CarePlan/NewCarePlan')));

// Departments 
const Departments = Loadable(lazy(() => import('../pages/Department/Departments')));
const NewDepartments = Loadable(lazy(() => import('../pages/Department/NewDepartments')));

// Doctors
const AddDoctor = Loadable(lazy(() => import('../pages/Doctors/AddDoctor')));
const DocAddress = Loadable(lazy(() => import('../pages/Doctors/DocAddress')));
const DocAppointmentinfo = Loadable(lazy(() => import('../pages/Doctors/DocAppointmentinfo')));
const DocBasicinfo = Loadable(lazy(() => import('../pages/Doctors/DocBasicinfo')));
const DocHolidays = Loadable(lazy(() => import('../pages/Doctors/DocHolidays')));
const Doctors = Loadable(lazy(() => import('../pages/Doctors/Doctors')));
const DocWebdata = Loadable(lazy(() => import('../pages/Doctors/DocWebdata')));

// User
const AddAddress = Loadable(lazy(() => import('../pages/User/AddAddress')));
const AddBasicinfo = Loadable(lazy(() => import('../pages/User/AddBasicinfo')));
const AddUser = Loadable(lazy(() => import('../pages/User/AddUser')));
const ListOfPassword = Loadable(lazy(() => import('../pages/User/ListOfPassword')));
const NewUserRole = Loadable(lazy(() => import('../pages/User/NewUserRole')));
const UserRole = Loadable(lazy(() => import('../pages/User/UserRole')));
const Users = Loadable(lazy(() => import('../pages/User/Users')));

// Forms
const ActivitiesForm = Loadable(lazy(() => import('../pages/Forms/ActivitiesForm')));
const Adhd = Loadable(lazy(() => import('../pages/Forms/Adhd')));
const AssignForms = Loadable(lazy(() => import('../pages/Forms/AssignForms')));
const Caic = Loadable(lazy(() => import('../pages/Forms/Caic')));
const Cpcr = Loadable(lazy(() => import('../pages/Forms/Cpcr')));
const FacialPain = Loadable(lazy(() => import('../pages/Forms/FacialPain')));
const Forms = Loadable(lazy(() => import('../pages/Forms/Forms')));
const Gad = Loadable(lazy(() => import('../pages/Forms/Gad')));
const StigmaForm = Loadable(lazy(() => import('../pages/Forms/StigmaForm')));
const SunshineAct = Loadable(lazy(() => import('../pages/Forms/SunshineAct')));
const NeuroConsent = Loadable(lazy(() => import('../pages/Forms/NeuroConsent')));
const NewForm = Loadable(lazy(() => import('../pages/Forms/NewForm')));
const Hipaa2 = Loadable(lazy(() => import('../pages/Forms/Hipaa2')));


// Email
const CareGiver = Loadable(lazy(() => import('../pages/Email/CareGiver')));
const EmailLog = Loadable(lazy(() => import('../pages/Email/EmailLog')));
const EmailSettings = Loadable(lazy(() => import('../pages/Email/EmailSettings')));
const EmailTemplate = Loadable(lazy(() => import('../pages/Email/EmailTemplate')));
const ForgotPassword = Loadable(lazy(() => import('../pages/Email/ForgotPassword')));
const NewAdmin = Loadable(lazy(() => import('../pages/Email/NewAdmin')));
const NewAppointment1 = Loadable(lazy(() => import('../pages/Email/NewAppointment1')));
const NewInvoice = Loadable(lazy(() => import('../pages/Email/NewInvoice')));
const NewPatient = Loadable(lazy(() => import('../pages/Email/NewPatient')));
const NewRequest = Loadable(lazy(() => import('../pages/Email/NewRequest')));
const NewWebsite = Loadable(lazy(() => import('../pages/Email/NewWebsite')));
const PaymentConfirmation = Loadable(lazy(() => import('../pages/Email/PaymentConfirmation')));
const ResetPassword = Loadable(lazy(() => import('../pages/Email/ResetPassword')));
const SendBulkEmail = Loadable(lazy(() => import('../pages/Email/SendBulkEmail')));
const SendEmail = Loadable(lazy(() => import('../pages/Email/SendEmail')));

// Settings
const Address = Loadable(lazy(() => import('../pages/Settings/Address')));
const BasicInfo = Loadable(lazy(() => import('../pages/Settings/BasicInfo')));
const ClinicalNotes = Loadable(lazy(() => import('../pages/Settings/ClinicalNotes')));
const ExpenseType = Loadable(lazy(() => import('../pages/Settings/ExpenseType')));
const InvoiceItems = Loadable(lazy(() => import('../pages/Settings/InvoiceItems')));
const NewExpense = Loadable(lazy(() => import('../pages/Settings/NewExpense')));
const NewItem = Loadable(lazy(() => import('../pages/Settings/NewItem')));
const NewNote = Loadable(lazy(() => import('../pages/Settings/NewNote')));
const NewPayment = Loadable(lazy(() => import('../pages/Settings/NewPayment')));
const NewSupplier = Loadable(lazy(() => import('../pages/Settings/NewSupplier')));
const NewTax = Loadable(lazy(() => import('../pages/Settings/NewTax')));
const PaymentGateway = Loadable(lazy(() => import('../pages/Settings/PaymentGateway')));
const PaymentMethod = Loadable(lazy(() => import('../pages/Settings/PaymentMethod')));
const SocialLink = Loadable(lazy(() => import('../pages/Settings/SocialLink')));
const Suppliers = Loadable(lazy(() => import('../pages/Settings/Suppliers')));
const SystemInfo = Loadable(lazy(() => import('../pages/Settings/SystemInfo')));
const TaxRate = Loadable(lazy(() => import('../pages/Settings/TaxRate')));

// Blog 
const Blog = Loadable(lazy(() => import('../pages/Blog/Blog')));
const Category = Loadable(lazy(() => import('../pages/Blog/Category')));
const Comment = Loadable(lazy(() => import('../pages/Blog/Comment')));
const NewPost = Loadable(lazy(() => import('../pages/Blog/NewPost')));




