import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

// @mui
import { 
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TextField,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableHead,
  TableContainer,
  TablePagination,
  emphasize, 
  styled, 
  Breadcrumbs, 
  Chip,
 } from '@mui/material';

 import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// hooks
import useSettings from '../../hooks/useSettings';

// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------
function createData(
    formname,
    button
    
  ) {
    return { formname, button, };
  }

  const rows = [
    createData('Rivermead Post-Concussion Symptoms',
    <Link to="/dashboard/forms/rpcs" >
          <Button variant="outlined" spacing={2}  >
            RPCS
          </Button>
          </Link>  ),
    createData('HIT', 
    <Link to="/dashboard/patientmonitoring/tmpquestionnaires/hit" >
          <Button variant="outlined" spacing={2}  >
            HIT
          </Button>
          </Link>),
    createData('BDI', 
    <Link to="/dashboard/patientmonitoring/tmpquestionnaires/bdi" >
          <Button variant="outlined" spacing={2}  >
            BDI
          </Button>
          </Link>),
    createData('NPI-Q', 
    <Link to="/dashboard/patientmonitoring/tmpquestionnaires/npi" >
          <Button variant="outlined" spacing={2}  >
            NPI-Q
          </Button>
          </Link>),
    createData('Epworth Sleepiness Scale', 
    <Link to="/dashboard/patientmonitoring/tmpquestionnaires/ess" >
          <Button variant="outlined" spacing={2}  >
            ESS
          </Button>
          </Link>),
    createData('Convergence Insufficiency Symptoms Survey', 
    <Link to="/dashboard/patientmonitoring/tmpquestionnaires/ciss" >
          <Button variant="outlined" spacing={2}  >
            CISS
          </Button>
          </Link>),
    createData('Medication History Checklist', 
    <Link to="/dashboard/patientmonitoring/tmpquestionnaires/medical" >
          <Button variant="outlined" spacing={2}  >
            Medical
          </Button>
          </Link>),
    createData('Patient Health Questionnaire', 
    <Link to="/dashboard/patientmonitoring/tmpquestionnaires/phq" >
          <Button variant="outlined" spacing={2}  >
            PHQ
          </Button>
          </Link>),
    createData('Headache Questionnaire', <Link to="/dashboard/patientmonitoring/tmpquestionnaires/nphq" >
    <Button variant="outlined" spacing={2}  >
      NPHQ
    </Button>
    </Link>
    ),
    createData('GAD-7', <Link to="/dashboard/forms/gad" >
    <Button variant="outlined" spacing={2}  >
      GAD-7
    </Button>
    </Link>),
    createData('Mini Nutritional Assessment', <Link to="/dashboard/patientmonitoring/tmpquestionnaires/mna" >
    <Button variant="outlined" spacing={2}  >
        MNA
    </Button>
    </Link>),
    createData('Modified Baecke', <Link to="/dashboard/patientmonitoring/tmpquestionnaires/mb" >
    <Button variant="outlined" spacing={2}  >
      MB
    </Button>
    </Link>),
    createData('LAWTON', <Link to="/dashboard/patientmonitoring/tmpquestionnaires/lawton" >
    <Button variant="outlined" spacing={2}  >
      Lawton
    </Button>
    </Link>),
    createData('Facial Pain Questionnaire', <Link to="/dashboard/forms/facialpain" >
    <Button variant="outlined" spacing={2}  >
      Facial pain
    </Button>
    </Link>),
    createData('ADHD', 
    <Link to="/dashboard/forms/adhd" >
    <Button variant="outlined" spacing={2}  >
      ADHD
    </Button>
    </Link>),
    createData('HIPAA Release Form', 
    <Link to="/dashboard/forms/hipaa2" >
    <Button variant="outlined" spacing={2}  >
      HIPAA
    </Button>
    </Link>),
    createData('Sunshine Act', 
    <Link to="/dashboard/forms/sunshineact" >
    <Button variant="outlined" spacing={2}  >
      Sunshine Act
    </Button>
    </Link>),
    createData('Informed Consent for Neuropsychological Assessment', 
    <Link to="/dashboard/forms/neuroconsent" >
          <Button variant="outlined" spacing={2}  >
            ICNA
          </Button>
          </Link>),
    createData('Informed Consent for Psychotherapy & Cognitive Rehabilitation Therapy', 
    <Link to="/dashboard/forms/cpcr" >
          <Button variant="outlined" spacing={2}  >
            CPCR
          </Button>
          </Link>),
    createData('Coaching Agreement and Informed Consent',
    <Link to="/dashboard/forms/caic" >
          <Button variant="outlined" spacing={2}  >
            CAIC
          </Button>
          </Link> ),
    createData('Katz', 
    <Link to="/dashboard/forms/activitiesform" >
          <Button variant="outlined" spacing={2}  >
            Katz
          </Button>
          </Link>),
    createData('Quality of life', 
    <Link to="/dashboard/forms/stigmaform" >
    <Button variant="outlined" spacing={2}  >
      Quality of life
    </Button>
    </Link>),

];

export default function PatientRegistration() {
  const { themeStretch } = useSettings();
  
  const [open, setOpen] = useState(null);

  const [selected, setSelected] = useState([]);


  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

 

  return (
    <>
    <Helmet>
        <title> Forms List | Kaizen Digital </title>
      </Helmet>
    <Page title="Page Two">
      <Container maxWidth={themeStretch ? false : 'xl'}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="column" spacing={0}>
          <Typography variant="h3" gutterBottom color="primary">
            Forms List 
          </Typography>
          <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography 
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary">
          Dashboard
        </Typography>
        
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          Forms
        </Typography>
      </Breadcrumbs>
    </div>
     </Stack>
          
          
        </Stack>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Form Name</TableCell>
            <TableCell align="center">View</TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
               <TableCell align="center">{row.formname}</TableCell>
              <TableCell align="center">{row.button}</TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
  />
    </TableContainer>
      </Container>
    </Page>
    </>
  );
}

